<div class="tile__background-border">
  <h2 class="client-access-title-padding">Client Access</h2>
  <p class="client-access-label">
    Search to add a client to the user's profile.
  </p>
  <mat-form-field class="user-client-select">
    <mat-select id="userClientAccessSelection" [disableOptionCentering]="true"
                [(value)]="clientDropdownOption"
                (selectionChange)="onDropDownChange()">
      <mat-option *ngFor="let clientDropdownOption of clientDropdownOptions;"
                  [value]="clientDropdownOption">
        {{clientDropdownOption.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-client-search
    style="display: inline-block; padding-bottom: 20px;" #appClientSearch
    [buttonClass]="'cvs-btn-ghost'"
    [clientSearchType]="clientSearchType"
    (searchOutput)="getClientManagementData($event)"
    (validationError)="onClientSearchValidationError()"
  >
  </app-client-search>

  <div class="client-error-text-message" *ngIf="showErrorResponse">
    No client(s) to display, search again or contact your client administrator to add the client.
  </div>

  <ag-grid-angular *ngIf="showGrid"
                   [columnDefs]="superClientIdColumnDefs"
                   [defaultColDef]="defaultColDef"
                   (gridReady)="onGridReady($event)"
                   [rowHeight]="32"
                   [context]="context"
                   [rowSelection]="'single'"
                   [rowModelType]="rowModelType"
                   [serverSideInfiniteScroll]="true"
                   [pagination]="true"
                   [cacheBlockSize]="50"
                   [suppressMenuHide]="true"
                   [headerHeight]="31"
                   [tooltipShowDelay]="300"
                   (rowSelected)="onRowSelected($event)"
                   [getRowClass]="rdcIsRowDisabled"
                   class="ag-theme-alpine client-access-grid">
  </ag-grid-angular>
  <app-paginator-rows-per-page
    *ngIf="showGrid"
    [defaultPaginatorSize]="defaultPaginatorSize"
    [pageSizeOptions]="[10, 25, 50]"
    [gridApi]="gridApi"
    [columnApi]="columnApi">
  </app-paginator-rows-per-page>
</div>

<div class='client-access'>
    <mat-expansion-panel #clientHierarchyExpansionPanel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="client-access-expansion-panel-title">Client Hierarchy Access</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="client-access-expansion-panel-sub-title">Note that each level of access is independent from one another.</p>

      <ul class="client-hierarchy-container">
        <li *ngFor="let client of clientHierarchy">
          <mat-checkbox [checked]="true" class="client-access-checkbox"
                        (change)="clientCheckboxChange($event)"
                        [value]="client"></mat-checkbox>
          {{client.clientName}}
        </li>
      </ul>

      <mat-error class="one-client-permitted-error"
                 *ngIf="showMoreThanOneClientSelectedError(undefined)">
        <mat-icon class="one-client-permitted-error-icon" svgIcon="error-f--xs"></mat-icon>
        <span class="one-client-permitted-error-message">Only one client is permitted. Remove other options and save.</span>
      </mat-error>
    </mat-expansion-panel>

    <mat-expansion-panel #superClientExpansionPanel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="client-access-expansion-panel-title">Super Client Access</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="client-access-expansion-panel-sub-title">Note that each level of access is independent from one another.</p>

      <div *ngFor="let superClientFg of userClientAccessWithLob.controls; let i=index;" class="super-client-container">
        <div class="super-client name" [formGroup]="superClientFg">
          <mat-checkbox class="client-access-checkbox" formControlName="clientSelected">
          </mat-checkbox>
          <a (click)="openClientProfileModal(superClientFg.get(ClientAccessFromGroup.CLIENT)?.value)"
             (keyup.space)="openClientProfileModal(superClientFg.get(ClientAccessFromGroup.CLIENT)?.value)"
             [tabIndex]="0">{{superClientFg.get(ClientAccessFromGroup.CLIENT)?.value.superClientName}}</a>
        </div>
        <div class="super-client lob">
          <mat-form-field>
            <mat-label>Add Line of business</mat-label>
            <mat-select [formControl]="superClientFg.get(ClientAccessFromGroup.LOBS_SELECTED)"
                        [disableOptionCentering]="true"
                        id="lobSelection" multiple>
              <div class="select-all-option" (click)="toggleAllSelection(superClientFg.get(ClientAccessFromGroup.LOBS_SELECTED), i)">
                <mat-checkbox #lobSelectAllCheckBox
                              [indeterminate]="isIndeterminate(superClientFg.get(ClientAccessFromGroup.LOBS_SELECTED))"
                              [checked]="lobSelectAllChecked(superClientFg.get(ClientAccessFromGroup.LOBS_SELECTED))"
                              (click)="$event.preventDefault()">
                  Select All
                </mat-checkbox>
              </div>
              <mat-option *ngFor="let lob of lobs" [value]="lob" (click)="toggleOption(superClientFg.get(ClientAccessFromGroup.LOBS_SELECTED), i)">
                {{lob}}
              </mat-option>
            </mat-select>
            <mat-hint>Optional</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <mat-error class="one-client-permitted-error"
                 *ngIf="showMoreThanOneClientSelectedForSuperClientLevel()">
        <mat-icon class="one-client-permitted-error-icon" svgIcon="error-f--xs"></mat-icon>
        <span class="one-client-permitted-error-message">Only one client is permitted. Remove other options and save.</span>
      </mat-error>
    </mat-expansion-panel>

    <mat-expansion-panel #clientCodeExpansionPanel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="client-access-expansion-panel-title">Client Code Access</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="client-access-expansion-panel-sub-title">Note that each level of access is independent from one another.</p>
      <ul>
        <li *ngFor="let client of clientCodes">
          <mat-checkbox [checked]="true" class="client-access-checkbox"
                        (change)="clientCheckboxChange($event)"
                        [value]="client"></mat-checkbox>
          <a (click)="openClientProfileModal(client)"
             (keyup.space)="openClientProfileModal(client)"
             [tabIndex]="0">{{client.clientCode}}</a>
        </li>
      </ul>

      <mat-error class="one-client-permitted-error"
                 *ngIf="showMoreThanOneClientSelectedError(ClientHierarchyEnum.CLIENT_CODE)">
        <mat-icon class="one-client-permitted-error-icon" svgIcon="error-f--xs"></mat-icon>
        <span class="one-client-permitted-error-message">Only one client is permitted. Remove other options and save.</span>
      </mat-error>
    </mat-expansion-panel>

    <mat-expansion-panel #carrierExpansionPanel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="client-access-expansion-panel-title">Carrier Access</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="client-access-expansion-panel-sub-title">Note that each level of access is independent from one another.</p>
      <ul>
        <li *ngFor="let carrier of carriers">
          <mat-checkbox [checked]="true" class="client-access-checkbox"
                        (change)="clientCheckboxChange($event)"
                        [value]="carrier"></mat-checkbox>
          <a
            (click)="openClientProfileModal(carrier, true)"
            (keyup.space)="openClientProfileModal(carrier,true)"
            [tabIndex]="0">{{carrier.carrierId}}</a>
        </li>
      </ul>

      <mat-error class="one-client-permitted-error"
                 *ngIf="showMoreThanOneClientSelectedError(ClientHierarchyEnum.CARRIER)">
        <mat-icon class="one-client-permitted-error-icon" svgIcon="error-f--xs"></mat-icon>
        <span class="one-client-permitted-error-message">Only one client is permitted. Remove other options and save.</span>
      </mat-error>
    </mat-expansion-panel>
</div>
