<div class="search-bar-container">
  <div class="search-bar">
    <mat-form-field aria-label="Search Input Field" appearance="legacy">
      <input matInput
             [formControl]="searchValue"
             placeholder="Search"
             (keydown.enter)="initiateClientSearch($event)"
      />
      <mat-hint>{{clientSearchLabel}}</mat-hint>
      <mat-error *ngIf="searchValue.hasError('exactlength')">
        <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
        {{searchValue.getError('exactlength').requiredLength}} characters required
      </mat-error>
      <mat-error *ngIf="searchValue.hasError('minlength')">
        <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
        {{searchValue.getError('minlength').requiredLength}} or more characters required
      </mat-error>
      <mat-error *ngIf="searchValue.hasError('required')">
        <mat-icon class="error-icon" svgIcon="error-f--xs"></mat-icon>
        {{clientSearchError ? clientSearchError: clientSearchLabel}}
      </mat-error>
    </mat-form-field>
    <mat-icon class="client-close"
              svgIcon="close-btn--s"
              [tabIndex]="0"
              *ngIf="searchValue.value"
              (click)="clearText()"
              (keydown.enter)="clearText()"
              aria-label="Clear Search">
    </mat-icon>
    <button aria-label="Search Button"
            class="{{buttonClass}} search-button"
            type="button"
            (click)="initiateClientSearch($event)"
            (keydown.enter)="initiateClientSearch($event)">
      Search
    </button>
  </div>
</div>
