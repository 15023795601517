import {ClientSearchType} from '../enums/client-search-type.enum';
import {ClientHierarchyEnum} from '../enums/client-hierarchy-enum';
import {ColDef} from 'ag-grid-community';
import {AddUserFormGroup} from '../enums/add-user-form.enum';

export const accountIdWithCheckbox = {
  headerName: 'SFDC Account ID',
  field: 'accountId',
  checkboxSelection: true,
  headerTooltip: 'Salesforce Account ID',
  minWidth: 180
};

export const accountId = {
  headerName: 'SFDC Account ID',
  field: 'accountId',
  headerTooltip: 'Salesforce Account ID',
  minWidth: 180
};

export const accountNameWithCheckbox = {
  headerName: 'SFDC Account Name',
  field: 'accountName',
  checkboxSelection: true,
  headerTooltip: 'Salesforce Account Name',
  minWidth: 200
};
export const accountName = {
  headerName: 'SFDC Account Name',
  field: 'accountName',
  headerTooltip: 'Salesforce Account Name',
  minWidth: 200
};

export const superClientIdWithCheckbox = {
  headerName: 'Super Client ID',
  field: 'superClientId',
  checkboxSelection: true,
  minWidth: 180
};
export const superClientId = {
  headerName: 'Super Client ID',
  field: 'superClientId',
  minWidth: 180
};

export const superClientIdWithLink = {
  headerName: 'Super Client ID',
  field: 'superClientId',
  cellRenderer: (params) => {
    if (params.value) {
      const a = document.createElement('a');
      a.innerText = params.value;
      a.addEventListener('click', event => {
        event.stopPropagation();
        params.context.this.setSearchFromLink(params.data, ClientSearchType.SUPER_CLIENT_ID, 'superClientId');
      });
      return a;
    } else  {
      return null;
    }
  },
  minWidth: 180
};

export const sfdcAccountIdWithLink = {
  headerName: 'SFDC Account ID',
  field: 'accountId',
  cellRenderer: (params) => {
    if (params.value) {
      const a = document.createElement('a');
      a.innerText = params.value;
      a.addEventListener('click', event => {
        event.stopPropagation();
        params.context.this.setSearchFromLink(params.data, ClientSearchType.SALESFORCE_ACCOUNT_ID, 'accountId');
      });
      return a;
    } else  {
      return null;
    }
  },
  minWidth: 200
};

export const superClientNameWithCheckbox = {
  headerName: 'Super Client Name',
  field: 'superClientName',
  checkboxSelection: true,
  minWidth: 200
};
export const superClientName = {
  headerName: 'Super Client Name',
  field: 'superClientName',
  minWidth: 200
};

export const status = {
  headerName: 'Status',
  field: 'status',
  filter: 'agSetColumnFilter',
  minWidth: 140
};

export const statusWithFilter = {
  headerName: 'Status',
  field: 'status',
  filter: 'agSetColumnFilter',
  filterParams: {
    values: ['Active', 'Inactive'],
    cellRenderer: (params) => {
      if (params.value === '(Select All)') {
        return '(Select All)';
      } else {
        return (params.value === 'Active') ? 'Active' : 'Inactive';
      }
    }
  },
  minWidth: 140
};

export const accountLevel = {
  headerName: 'SFDC Account Level',
  field: 'accountLevel',
  headerTooltip: 'Salesforce Account Level',
  minWidth: 200
};

export const superClientRdcLevel = {
  headerName: 'RDC Level',
  field: 'superClientRdcLevel',
  headerTooltip: 'Restricted Data Client Level',
  minWidth: 180
};

export const rdcLevel = {
  headerName: 'RDC Level',
  field: 'rdcLevel',
  headerTooltip: 'Restricted Data Client Level',
  minWidth: 180
};

export const superClientRdcAccountWithFilter = {
  headerName: 'RDC Account',
  field: 'isRDCAccount',
  filter: 'agSetColumnFilter',
  filterParams: {
    values: ['Yes', 'No'],
    cellRenderer: (params) => {
      if (params.value === '(Select All)') {
        return '(Select All)';
      } else {
        return (params.value === 'Yes') ? 'Yes' : 'No';
      }
    },
  },
  headerTooltip: 'Restricted Data Client Account',
  minWidth: 180
};

export const marketSegment = {
  headerName: 'Market Segment',
  field: 'marketSegment',
  minWidth: 180
};

export const clientCodeWithCheckbox = {
  headerName: 'Client Code',
  field: 'clientCode',
  checkboxSelection: true,
  suppressMenu: true,
  minWidth: 180
};

export const clientNameWithCheckbox: ColDef = {
  headerName: 'Client Name',
  field: 'clientName',
  checkboxSelection: true,
  cellStyle: params => {
    if (params.data.rdcLevel === 'All' &&
      params.context.this.addUserForm.get(AddUserFormGroup.LOCATION).value === 'Offshore') {
      return { opacity: '0.4' };
    }
    return null;
  },
  tooltipValueGetter: (params) => {
    if (params.data.rdcLevel === 'All' &&
      params.context.this.addUserForm.get(AddUserFormGroup.LOCATION).value === 'Offshore') {
      return 'Offshore users cannot be provisioned to RDC clients.';
    }
  },
  suppressMenu: true,
  minWidth: 300
};

export const clientNameRdcAccount = {
  headerName: 'RDC Account',
  field: 'isRDCAccount',
  suppressMenu: true,
  headerTooltip: 'Restricted Data Client Account',
  cellStyle: params => {
    if (params.data.rdcLevel === 'All' &&
      params.context.this.addUserForm.get(AddUserFormGroup.LOCATION).value === 'Offshore') {
      return { opacity: '0.4' };
    }
    return null;
  },
  minWidth: 180
};

export const clientNameRdcLevel = {
  headerName: 'RDC Level',
  field: 'rdcLevel',
  suppressMenu: true,
  headerTooltip: 'Restricted Data Client Level',
  cellStyle: params => {
    if (params.data.rdcLevel === 'All' &&
      params.context.this.addUserForm.get(AddUserFormGroup.LOCATION).value === 'Offshore') {
      return { opacity: '0.4' };
    }
    return null;
  },
  minWidth: 180
};

export const clientCode = {
  headerName: 'Client Code',
  field: 'clientCode',
  minWidth: 180
};

export const clientCodeWithNoFilter = {
  headerName: 'Client Code',
  field: 'clientCode',
  suppressMenu: true,
  minWidth: 180
};

export const clientName = {
  headerName: 'Client Name',
  field: 'clientName',
  minWidth: 300
};

export const clientProfile = {
  headerName: 'Client Profile',
  field: '',
  filter: false,
  suppressMenu: true,
  valueGetter: (params) => {
    return 'View Client Profile';
  },
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.value;
    a.addEventListener('click', event => {
      event.stopPropagation();
      params.context.this.getFullHierarchyForClientAccess(params.data.id);
    });
    return a;
  },
  minWidth: 180
};

export const clientProfileForUserClientAccess = {
  headerName: 'Client Profile',
  field: '',
  filter: false,
  suppressMenu: true,
  valueGetter: (params) => {
    return 'View Client Profile';
  },
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.value;
    a.addEventListener('click', event => {
      event.stopPropagation();
      const isFromCarrier = params.data.accountLevel === ClientHierarchyEnum.CARRIER ? true : null;
      params.context.this.openClientProfileModal(params.data, isFromCarrier);
    });
    return a;
  },
  minWidth: 180
};

export const clientProfileForAddClient = {
  headerName: 'Client Profile',
  field: '',
  filter: false,
  suppressMenu: true,
  valueGetter: (params) => {
    return 'View Client Profile';
  },
  cellRenderer: (params) => {
    const a = document.createElement('a');
    a.innerText = params.value;
    a.addEventListener('click', event => {
      event.stopPropagation();
      params.context.this.getFullHierarchyForAddClientAccess(params.data);
    });
    return a;
  },
  minWidth: 180
};


export const clients = {
  headerName: 'Client Code',
  field: '',
  filter: false,
  suppressMenu: true,
  valueGetter: (params) => {
    return 'View Client Code List';
  },
  cellStyle: {color: '#0B315E', cursor: 'pointer', 'text-decoration': 'underline'},
  minWidth: 180
};

export const carriers = {
  headerName: 'Carrier(s)',
  field: 'carriers',
  filter: false,
  suppressMenu: true,
  valueGetter: (params) => {
    return 'View Carrier List';
  },
  cellStyle: {color: '#0B315E', cursor: 'pointer', 'text-decoration': 'underline'},
  minWidth: 180
};

export const carrierId = {
  headerName: 'Carrier ID',
  field: 'carrierId',
  minWidth: 180
};

export const carrierIdWithCheckbox = {
  headerName: 'Carrier ID',
  field: 'carrierId',
  checkboxSelection: true,
  minWidth: 180
};

export const carrierIdWithLink = {
  headerName: 'Carrier ID',
  field: 'carrierId',
  cellRenderer: (params) => {
    if (params.value) {
      const a = document.createElement('a');
      a.innerText = params.value;
      a.addEventListener('click', event => {
        event.stopPropagation();
        params.context.this.setSearchFromLink(params.data, ClientSearchType.CARRIER_ID, 'carrierId');
      });
      return a;
    } else  {
      return null;
    }
  },
  minWidth: 200
};

export const carrierName = {
  headerName: 'Carrier Name',
  field: 'carrierName',
  minWidth: 180
};

export const carrierNameWithCheckbox = {
  headerName: 'Carrier Name',
  field: 'carrierName',
  checkboxSelection: true,
  minWidth: 180
};

export const carrierRdcLevel = {
  headerName: 'RDC Level',
  field: 'carrierRdcLevel',
  headerTooltip: 'Restricted Data Client Level',
  minWidth: 180
};

export const fromDate = {
  headerName: 'From Date',
  field: 'fromDate',
  minWidth: 180
};

export const thruDate = {
  headerName: 'Thru Date',
  field: 'thruDate',
  minWidth: 180
};

